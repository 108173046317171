import { User } from "@api/types";

import classNames from "classnames";
import UserProfileBg from "../../static/images/user-profile-bg.jpg";

import bs from "../../css/BootstrapCss";

export const EmptyProfile = ({ user }: { user: User }) => {
  return (
    <div
      className={classNames(bs["w-100"])}
      style={{
        maxWidth: "800px",
      }}
    >
      <div
        style={{
          background: `url(${UserProfileBg}) no-repeat center center`,
          backgroundSize: "cover",
          height: "350px",
          borderRadius: "8px",
        }}
        className={classNames(
          bs["d-flex"],
          bs["flex-column"],
          bs["justify-content-center"],
          bs["align-items-center"]
        )}
      >
        <span className={classNames(bs["text-uppercase"], bs["text-white"])}>
          {user.firstName} {user.lastName}
        </span>
        <h2
          className={classNames(
            bs["mt-1"],
            bs["font-weight-light"],
            bs["text-white"]
          )}
        >
          Terveysprofiili
        </h2>
      </div>
      <div className={classNames(bs["mt-5"])}>
        <div className={classNames(bs["mb-5"])}>
          <h2 className={classNames(bs["mb-4"])}>Hei {user.firstName}!</h2>
          <p>Tervetuloa Oma Viven -palveluun!</p>
          <p>
            Aloita ostamalla{" "}
            <a href="https://oma.viven.fi/palvelut/terveysanalyysi">tästä</a>{" "}
            naisen täydellinen terveysanalyysi. Analyysi luo sinulle selkeän
            kuvan kehosi ja hormonitoimintasi tilasta. Saat naisten terveyteen
            perehtyneeltä lääkäriltä konkreettisia ohjeita ja työkaluja
            terveytesi parantamiseen.
          </p>
          <p>
            Analyysiin kuuluu kattava terveyskysely sekä laboratoriotestit.
            Tulokset saat tänne Oma Viven -palveluun heti kun ne ovat valmiit.
          </p>
          <p>
            Mikäli sinulla on kysyttävää tai haluat varata lääkärillesi erikseen
            maksullisen etätapaamisen, voit olla yhteydessä osoitteeseen{" "}
            <a
              className={classNames(bs["text-dark"])}
              href="mailto:asiakaspalvelu@viven.fi"
            >
              asiakaspalvelu@viven.fi
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};
