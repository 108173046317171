import { Link } from "react-router-dom";
import "./Welcome.css";

import MainBg from "./main-page.jpg";
import Logo from "./viven-white.svg";
import Lock from "./lock-64.png";

export default function Welcome() {
  return (
    <div
      style={{
        background: `url(${MainBg}) no-repeat center center`,
        backgroundSize: "cover",
      }}
      className="welcome-container"
    >
      <div className="logo-container">
        <Link className="logo-link" to={`https://viven.fi`} target="_top">
          <img src={Logo} alt="Viven" />
        </Link>
      </div>
      <div className="login-container">
        <div>
          <p>Voit kirjautua Oma Viven -palveluun verkkopankkitunnuksillasi.</p>
          <p>
            <img src={Lock} alt="Turvallinen kirjautuminen" width={20} />
            <Link className="login-link" to={`/api/auth/init`} target="_top">
              Kirjaudu
            </Link>
          </p>
        </div>
      </div>
      <div className="privacy-container">
        <div>
          <Link
            className="login-link"
            to={`https://viven.fi/tietosuoja`}
            target="_top"
          >
            Tietosuoja
          </Link>
        </div>
      </div>
    </div>
  );
}
