import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const CircularProgressWithLabel = ({
  value,
  color = "black",
  circleSize = 70,
  fontSize = 18,
}: {
  value: number;
  color?: string;
  circleSize?: number;
  fontSize?: number;
}) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={circleSize}
        sx={{
          color: color,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          fontSize={fontSize}
          color={color}
          variant="caption"
          component="div"
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};
