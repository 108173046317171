import { User } from "@api/types";
import axios, { AxiosProgressEvent } from "axios";
import { useCallback, useEffect, useState } from "react";

import VideoBg from "../../static/images/jenni-video-bg.jpg";
import VideoPlayIcon from "../../static/images/play-icon.png";

import classNames from "classnames";
import UserProfileBg from "../../static/images/user-profile-bg.jpg";

import bs from "../../css/BootstrapCss";
import "./CustomerProfile.css";

import { UserProgram, UserProgramFileType } from "api/src/domain/io-ts-types";
import { find } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  base64ToUint8Array,
  decryptFileContent,
  decryptStoredKey,
} from "../../utils/encryption-utils";
import { CircularProgressWithLabel } from "./CircularProgress";

const triggerUIDownload = (url: string, name: string) => {
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", name);

  if (typeof link.download === "undefined") {
    link.setAttribute("target", "_blank");
  }

  document.body.appendChild(link);
  link.click();

  setTimeout(function () {
    try {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error("Error with link removal", e);
    }
  }, 300);
};

const getCheckupProgram = (user: User): UserProgram | undefined => {
  const programExists = find(
    user.programs,
    (p) => p.extId === "perimenopause-one-time-checkup"
  );
  return programExists && programExists;
};

const hasProgramReport = (user: User): boolean => {
  const existingProgram = getCheckupProgram(user);
  return (
    existingProgram?.analysisReportFile !== null &&
    existingProgram?.analysisVideoFile !== null
  );
};

export const CustomerProfile = ({
  user,
  program,
}: {
  user: User;
  program: UserProgram;
}) => {
  const [isReportAvailable] = useState<boolean>(hasProgramReport(user));
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [pdfDownloadProgress, setPdfDownloadProgress] = useState<number>(0);
  const [downloadingVideoFile, setDownloadingVideoFile] =
    useState<boolean>(false);
  const [videoFileDownloadProgress, setVideoFileDownloadProgress] =
    useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [videoDownloadProgress, setVideoDownloadProgress] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string | undefined>();
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const navigate = useNavigate();

  const getS3FileDetails = useCallback(
    async (fileType: UserProgramFileType) => {
      const response = await fetch(
        `/api/customer-data/download/presigned-url`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            programExtId: program.extId,
            fileType: fileType,
          }),
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to retrieve presigned url.");
      }
      return await response.json();
    },
    [program]
  );

  const getAxiosFile = async (
    url: string,
    onDownloadProgress: (progressEvent: AxiosProgressEvent) => void
  ) => {
    try {
      return await axios.get(url, {
        responseType: "arraybuffer",
        onDownloadProgress: onDownloadProgress,
      });
    } catch (error) {
      console.error("Error with Axios download", error);
      throw new Error("Failed to get file content from server");
    }
  };

  const getFileAsBlobUrl = useCallback(
    async (
      fileType: UserProgramFileType,
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => void
    ) => {
      const s3FileDetails = await getS3FileDetails(fileType);
      const s3FileResponse = await getAxiosFile(
        s3FileDetails.url,
        onDownloadProgress
      );

      const aesKey = await decryptStoredKey(s3FileDetails.aesKey);
      const iv = base64ToUint8Array(s3FileDetails.iv);

      const decryptedData = await decryptFileContent(
        aesKey,
        iv,
        s3FileResponse.data
      );
      const blob = new Blob([decryptedData], {
        type: s3FileDetails.fileContentType,
      });
      return URL && URL.createObjectURL
        ? URL.createObjectURL(blob)
        : webkitURL.createObjectURL(blob);
    },
    [getS3FileDetails]
  );

  const downloadReportPdf = useCallback(async () => {
    setDownloadingReport(true);
    try {
      const url = await getFileAsBlobUrl("analysis_report", (progressEvent) => {
        setPdfDownloadProgress(
          Math.round(
            (progressEvent.loaded /
              (progressEvent.total ? progressEvent.total : 1)) *
              100
          )
        );
      });
      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = url;
      } else {
        triggerUIDownload(url, "terveysraportti.pdf");
      }
    } catch (error) {
      console.error("Error getting download URL or downloading file:", error);
      toast.error(
        "Raporttidokumentin haku epäonnistui. Laitathan meille viestiä osoitteeseen asiakaspalvelu@viven.fi mikäli ongelma ei ratkea.",
        {
          autoClose: 3000,
        }
      );
    } finally {
      setDownloadingReport(false);
      setPdfDownloadProgress(0);
    }
  }, [getFileAsBlobUrl]);

  const downloadReportVideo = useCallback(async () => {
    setDownloadingVideoFile(true);
    try {
      const url = await getFileAsBlobUrl("analysis_video", (progressEvent) => {
        setVideoFileDownloadProgress(
          Math.round(
            (progressEvent.loaded /
              (progressEvent.total ? progressEvent.total : 1)) *
              100
          )
        );
      });
      triggerUIDownload(url, "terveysraportti.mp4");
    } catch (error) {
      console.error("Error getting download URL or downloading file:", error);
      toast.error(
        "Raporttidokumentin haku epäonnistui. Laitathan meille viestiä osoitteeseen asiakaspalvelu@viven.fi mikäli ongelma ei ratkea.",
        {
          autoClose: 3000,
        }
      );
    } finally {
      setDownloadingVideoFile(false);
      setVideoFileDownloadProgress(0);
    }
  }, [getFileAsBlobUrl]);

  const downloadVideo = useCallback(async () => {
    try {
      const url = await getFileAsBlobUrl("analysis_video", (progressEvent) => {
        setVideoDownloadProgress(
          Math.round(
            (progressEvent.loaded /
              (progressEvent.total ? progressEvent.total : 1)) *
              100
          )
        );
      });
      setVideoUrl(url);
    } catch (error) {
      console.error("Error getting download URL or downloading file:", error);
      toast.error(
        "Raporttivideon haku epäonnistui. Laitathan meille viestiä osoitteeseen asiakaspalvelu@viven.fi mikäli ongelma ei ratkea.",
        {
          autoClose: 3000,
        }
      );
    } finally {
      setVideoDownloadProgress(0);
    }
  }, [getFileAsBlobUrl]);

  useEffect(() => {
    if (isReportAvailable) {
      downloadVideo();
    }
  }, [isReportAvailable, getFileAsBlobUrl, downloadReportPdf, downloadVideo]);

  return (
    <div className={classNames("customer-profile")}>
      <div
        className={classNames(bs["w-100"])}
        style={{
          maxWidth: "800px",
        }}
      >
        <div
          style={{
            background: `url(${UserProfileBg}) no-repeat center center`,
            backgroundSize: "cover",
            height: "350px",
            borderRadius: "8px",
          }}
          className={classNames(
            bs["d-flex"],
            bs["flex-column"],
            bs["justify-content-center"],
            bs["align-items-center"]
          )}
        >
          <span className={classNames(bs["text-uppercase"], bs["text-white"])}>
            {user.firstName} {user.lastName}
          </span>
          <h2
            className={classNames(
              bs["mt-1"],
              bs["font-weight-light"],
              bs["text-white"]
            )}
          >
            Naisen terveysprofiili
          </h2>
        </div>
        {isReportAvailable ? (
          <div className={classNames(bs["mt-5"])}>
            <div className={classNames(bs["mb-5"])}>
              <h2 className={classNames(bs["mb-4"])}>Hei {user.firstName}!</h2>
              <p>
                Alta löydät nyt lääkärisi raportin ja videon
                terveystarkastuksesi tuloksista, löydöksistä ja
                jatkosuunnitelmasta.
              </p>
              <p>
                Mikäli sinulla on kysyttävää tai haluat varata lääkärillesi
                erikseen maksullisen etätapaamisen, voit olla yhteydessä
                osoitteeseen{" "}
                <a
                  className={classNames(bs["text-dark"])}
                  href="mailto:asiakaspalvelu@viven.fi"
                >
                  asiakaspalvelu@viven.fi
                </a>
                .
              </p>
            </div>
            <div className={classNames(bs["mb-5"])}>
              <h4 className={classNames(bs["mb-4"])}>Raporttisi</h4>
              <button
                className={classNames(
                  bs["btn"],
                  bs["btn-dark"],
                  bs["d-flex"],
                  bs["justify-content-center"],
                  bs["align-items-center"]
                )}
                style={{
                  width: "300px",
                  height: "48px",
                }}
                onClick={() => !downloadingReport && downloadReportPdf()}
              >
                {downloadingReport ? (
                  <CircularProgressWithLabel
                    value={pdfDownloadProgress}
                    color="white"
                    circleSize={38}
                    fontSize={12}
                  />
                ) : (
                  "Lataa PDF-raporttisi tästä"
                )}
              </button>
            </div>
            <div className={classNames(bs["mb-5"])}>
              <h4 className={classNames(bs["mb-4"])}>Lääkärisi video</h4>
              <div
                className={classNames(
                  "video-container",
                  playVideo && videoUrl && "video-playing"
                )}
                role={"button"}
              >
                {playVideo && videoUrl ? (
                  <video
                    controls
                    width="100%"
                    height={"auto"}
                    src={videoUrl}
                    style={{
                      borderRadius: "8px",
                    }}
                    autoPlay
                    className="video-player"
                  >
                    Selaimesi ei tue videon toistoa.
                  </video>
                ) : (
                  <div
                    style={{
                      background: `url(${VideoBg}) no-repeat center center`,
                      backgroundSize: "cover",
                    }}
                    className={classNames(
                      "video-placeholder",
                      bs["d-flex"],
                      bs["justify-content-center"],
                      bs["align-items-center"]
                    )}
                    onClick={() => setPlayVideo(true)}
                  >
                    {playVideo && !videoUrl ? (
                      <div
                        className={classNames(
                          bs["d-flex"],
                          bs["flex-column"],
                          bs["align-items-center"]
                        )}
                      >
                        <CircularProgressWithLabel
                          value={videoDownloadProgress}
                        />
                      </div>
                    ) : (
                      <img
                        src={VideoPlayIcon}
                        width={100}
                        height={100}
                        alt="Raporttivideo"
                        className={classNames("play-button")}
                      />
                    )}
                  </div>
                )}
              </div>
              <p className={classNames(bs["mt-3"])}>
                Voit myös ladata videoanalyysin koneellesi{" "}
                <button
                  className={classNames(bs["btn"], bs["btn-link"])}
                  style={{
                    padding: 0,
                    color: "#000000",
                    verticalAlign: "inherit",
                    fontSize: "18px",
                    textDecoration: "underline",
                  }}
                  onClick={() => !downloadingVideoFile && downloadReportVideo()}
                >
                  {"tästä"}
                </button>
                . Huomioithan, että tiedosto on kooltaan melko suuri.
                {downloadingVideoFile && (
                  <div className={classNames(bs["mt-3"])}>
                    Ladataan tiedostoa... {`${videoFileDownloadProgress}%`}
                  </div>
                )}
              </p>
            </div>
            <hr />
            <div className={classNames(bs["mt-3"], bs["mb-3"])}>
              <h4 className={classNames(bs["mb-4"])}>Palautekysely</h4>
              <p>
                Sinun mielipiteesi on meille todella tärkeä ja toivomme, että
                ehdit täyttämään palautekyselymme. Kysely vie vain muutaman
                minuutin ja sen avulla pystymme kehittämään palvelua entistä
                paremmaksi.
              </p>
              <button
                className={classNames(
                  bs["btn"],
                  bs["btn-dark"],
                  bs["d-flex"],
                  bs["justify-content-center"],
                  bs["align-items-center"]
                )}
                style={{
                  width: "300px",
                  height: "48px",
                  backgroundColor: "#fe5000",
                  border: 0,
                }}
                onClick={() =>
                  navigate(
                    `/palvelut/terveysanalyysi/kysely/terveysanalyysi-palaute/${user.externalId}`
                  )
                }
              >
                Täytä palautekysely
              </button>
            </div>
          </div>
        ) : (
          <div className={classNames(bs["mt-5"])}>
            <div className={classNames(bs["mb-5"])}>
              <h2 className={classNames(bs["mb-4"])}>Hei {user.firstName}!</h2>

              <p>
                Täältä löydät lääkärisi raportin ja videon terveystarkastuksesi
                tuloksista, löydöksistä ja jatkosuunnitelmasta.
              </p>
              <p>Raportin tekeminen on tällä hetkellä vielä kesken.</p>
              <p>
                Mikäli sinulla on kysyttävää tai haluat varata lääkärillesi
                erikseen maksullisen etätapaamisen, voit olla yhteydessä
                osoitteeseen{" "}
                <a
                  className={classNames(bs["text-dark"])}
                  href="mailto:asiakaspalvelu@viven.fi"
                >
                  asiakaspalvelu@viven.fi
                </a>
                .
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
