import { User } from "@api/types";
import { useEffect, useState } from "react";
import { Link, Route, Routes, useSearchParams } from "react-router-dom";
import { BouncingDotsIndicator } from "../components/BouncingDotsIndicator";
import { ToastContainer, toast } from "react-toastify";
import { MainPage } from "./Main";

import Logo from "../static/images/text_logo.svg";

import classNames from "classnames";
import "./App.css";
import styles from "../css/BootstrapCss";
import Welcome from "./Welcome";

export default function App() {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState<User | undefined | null>(undefined);

  useEffect(() => {
    if (searchParams.get("autherror") !== null) {
      toast.error(
        "Tunnistautuminen epäonnistui. Mikäli ongelma ei ratkea, laitathan asiakaspalveluumme viestiä osoitteeseen asiakaspalvelu@viven.fi.",
        {
          autoClose: 3000,
        }
      );
    }
    if (searchParams.get("authabort") !== null) {
      toast.warning("Tunnistautuminen keskeytyi.", {
        autoClose: 3000,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchUser = async () => {
      const endpoint = `/api/user`;
      const response = await fetch(`${endpoint}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 401) {
        setUser(null);
      } else {
        const userJson = await response.json();
        setUser(userJson);
      }
    };
    fetchUser().catch(console.error);
  }, []);

  return (
    <>
      <ToastContainer theme="light" />
      {user === null ? (
        <div className={styles.bootstrap}>
          <Welcome />
        </div>
      ) : (
        <div className={styles.bootstrap}>
          {user === undefined ? (
            <div
              className={classNames(
                styles["vh-100"],
                styles["justify-content-center"],
                styles["align-items-center"],
                styles["d-flex"]
              )}
            >
              <BouncingDotsIndicator />
            </div>
          ) : (
            <>
              <nav
                className={classNames(
                  styles["navbar"],
                  styles["justify-content-between"],
                  styles["border-bottom"],
                  styles["navbar-expand-lg"],
                  styles["navbar-light"]
                )}
              >
                <Link className={styles["navbar-brand"]} to={"/"}>
                  <img src={Logo} alt="Viven" height={23} />
                </Link>

                <Link
                  className={classNames(
                    styles["btn"],
                    styles["btn-outline-dark"]
                  )}
                  to={`/api/auth/logout`}
                  reloadDocument
                >
                  Kirjaudu ulos
                </Link>
              </nav>

              <div className="app-content">
                <Routes>
                  <Route path="/" element={<MainPage user={user} />} />
                </Routes>
              </div>
            </>
          )}
        </div>
      )}
      ;
    </>
  );
}
