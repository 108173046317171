import classNames from "classnames";

export const BouncingDotsIndicator = ({ color }: { color?: "white" }) => {
  return (
    <>
      <div className={classNames("douncing-dots", color && color)}>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </>
  );
};
