import classNames from "classnames";
import styles from "../css/BootstrapCss";

export default function Error() {
  return (
    <div className={styles.bootstrap}>
      <div
        className={classNames(styles["align-middle"], styles["text-center"])}
      >
        <h1>Vested Health</h1>
        <p>Järjestelmässä tapahtui virhe. Ole hyvä ja yritä uudelleen.</p>
      </div>
    </div>
  );
}
