import { User } from "@api/types";
import { useState } from "react";

import classNames from "classnames";

import bs from "../css/BootstrapCss";

import { UserProgram } from "api/src/domain/io-ts-types";
import { find } from "lodash";
import { ToastContainer } from "react-toastify";
import { CustomerProfile } from "../components/customer-profile/CustomerProfile";
import { EmptyProfile } from "../components/customer-profile/EmptyProfile";

const getCheckupProgram = (user: User): UserProgram | undefined => {
  const programExists = find(
    user.programs,
    (p) => p.extId === "perimenopause-one-time-checkup"
  );
  return programExists && programExists;
};

const hasProgram = (user: User): boolean => {
  return getCheckupProgram(user) !== undefined;
};

export function MainPage({ user }: { user: User }) {
  const [isProgram] = useState<boolean>(hasProgram(user));

  return (
    <div className={classNames(bs.bootstrap)}>
      <ToastContainer theme="light" />
      <div
        className={classNames(
          bs["container-fluid"],
          bs["mt-5"],
          bs["d-flex"],
          bs["align-items-center"],
          bs["flex-column"]
        )}
      >
        {user === undefined ? (
          <></>
        ) : isProgram ? (
          <CustomerProfile user={user} program={getCheckupProgram(user)!} />
        ) : (
          <EmptyProfile user={user} />
        )}
      </div>
    </div>
  );
}
