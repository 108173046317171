import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import "./Welcome.css";
import styles from "../css/BootstrapCss";
import classNames from "classnames";

export default function Page404() {
  return (
    <div className={classNames("full-page", styles.bootstrap)}>
      <ToastContainer theme="light" />
      <div
        className={classNames(styles["text-center"], styles["align-middle"])}
      >
        <h2>Hakemaasi sivua ei löytynyt</h2>
        <Link
          className={classNames(
            styles["btn"],
            styles["btn-outline-dark"],
            styles["mt-2"]
          )}
          to={`https://viven.fi`}
          target="_top"
        >
          Viven
        </Link>
      </div>
    </div>
  );
}
