import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Model } from "survey-core";
import { ToastContainer, toast } from "react-toastify";

import SurveyPage, { ISurvey } from "../components/Survey";
import { BouncingDotsIndicator } from "../components/BouncingDotsIndicator";
import { toProgramType, trackEvent } from "../analytics/ga";

import "./Program.css";
import classNames from "classnames";

function Program() {
  const { programName } = useParams();
  const [survey, setSurvey] = useState<ISurvey>();
  const [error, setError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const onSubmit = async (data: any, done: any) => {
    trackEvent("survey_submit", toProgramType(programName));
    const response = await fetch(`/api/program/authenticate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...data, programName }),
    });
    if (response.status === 200) {
      trackEvent("identification_start", toProgramType(programName));
      window.location = (await response.json()).redirectUrl;
    } else {
      toast.error(
        "Tietojen tallennus epäonnistui. Laitathan meille viestiä osoitteeseen asiakaspalvelu@viven.fi mikäli ongelma ei ratkea.",
        {
          autoClose: 3000,
        }
      );
      done(false);
    }
  };

  // 'init_customer' page triggers an event to create/update the customer in customer.io
  const initializeCustomer = async (data: any) => {
    fetch(`/api/user/initiate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: data.email,
        phone: data.phone,
      }),
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const endpoint = `/api/program/${programName}/init`;
      const response = await fetch(`${endpoint}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (response.status !== 200) {
        setError(true);
      } else {
        const surveyJson = await response.json();
        setSurvey({
          id: surveyJson.id,
          model: new Model(surveyJson.data),
          programName,
        });
        trackEvent("survey_start", toProgramType(programName));
        trackEvent(
          "conversion_event_begin_checkout",
          toProgramType(programName)
        );
      }
    };
    fetchData().catch(console.error);

    if (searchParams.get("autherror") !== null) {
      toast.error(
        "Tunnistautuminen epäonnistui. Laitathan meille viestiä osoitteeseen asiakaspalvelu@viven.fi mikäli ongelma ei ratkea.",
        {
          autoClose: 3000,
        }
      );
    }
    if (searchParams.get("authabort") !== null) {
      toast.warning("Tunnistautuminen keskeytyi.", {
        autoClose: 3000,
      });
    }
  }, [programName, searchParams]);

  return (
    <>
      <div className={classNames("program")}>
        <ToastContainer theme="light" />
        {error ? (
          <div className="error">Hakemaasi sisältöä ei löytynyt</div>
        ) : survey ? (
          <>
            <SurveyPage
              id={survey.id}
              model={survey.model}
              programName={programName}
              onSubmit={onSubmit}
              initializeCustomer={initializeCustomer}
            />
          </>
        ) : (
          <div className="loading">
            <BouncingDotsIndicator />
          </div>
        )}
      </div>
    </>
  );
}

export default Program;
