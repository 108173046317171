import ReactGA from "react-ga4";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const trackEvent = (
  event: EventType,
  programType: ProgramType,
  eventProps?: any
) => {
  if (process.env.NODE_ENV === "production") {
    //TODO: One of these is probably enough?
    ReactGA.event(event, {
      category: "survey",
      action: "view",
      label: programType,
      ...eventProps,
    });
    window.dataLayer &&
      window.dataLayer.push({
        event,
        eventProps: {
          category: "survey",
          action: "view",
          label: programType,
          ...eventProps,
        },
      });
  }
};

type EventType =
  | "conversion_event_begin_checkout"
  | "survey_start"
  | "init_customer"
  | "survey_complete"
  | "survey_submit"
  | "identification_start"
  | "identification_complete"
  | "follow_up_survey_start"
  | "follow_up_survey_complete"
  | "unknown";

type ProgramType = "weightloss" | "unknown" | "perimenopause-one-time-checkup";

export const toProgramType = (
  programName: string | undefined | null
): ProgramType => {
  if (
    programName === "laihdutus" ||
    programName === "painonpudotus" ||
    programName === "weightloss"
  )
    return "weightloss" as const;
  if (programName === "perimenopause-one-time-checkup")
    return "perimenopause-one-time-checkup" as const;

  return "unknown" as const;
};
