const { subtle } = globalThis.crypto;

const ENCRYPTION_MODE = "AES-CTR";

export const base64ToUint8Array = (base64: string): Uint8Array => {
  const binaryString = atob(base64);
  return Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
};

export const decryptStoredKey = async (
  base64AesKey: string
): Promise<CryptoKey> => {
  const rawAesKey = base64ToUint8Array(base64AesKey);

  return await subtle.importKey(
    "raw",
    rawAesKey,
    { name: ENCRYPTION_MODE },
    true,
    ["encrypt", "decrypt"]
  );
};

export const decryptFileContent = async (
  aes: CryptoKey,
  iv: Uint8Array,
  content: ArrayBuffer
): Promise<Uint8Array> => {
  const decrypted = await subtle.decrypt(
    {
      name: ENCRYPTION_MODE,
      counter: iv,
      length: 128,
    },
    aes,
    content
  );

  return new Uint8Array(decrypted);
};
